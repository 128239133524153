export default class FtArPaymentd{
    constructor(
        id=0,
        noUrut=0,

        cashAmountPay=0.0,
        uangMukaAmountPay=0.0,
        returAmountPay=0.0,
        giroAmountPay=0.0,
        transferAmountPay=0.0,

        potonganAmount=0.0,
        potonganNotes ="",

        ftArPaymenthBean =0,
        ftSaleshBean=0,
        returBean=0,
        fgiroBean=0,
        accAccountPotonganBean=0,
        fuangMukaBean=0,

        fdivisionBean,
        ref ="",
        refDate,
        remark ="",

        created,
        modified,
        modifiedBy

    ) {
        this.id = id;
        this.noUrut = noUrut;

        this.cashAmountPay = cashAmountPay;
        this.uangMukaAmountPay = uangMukaAmountPay;
        this.returAmountPay = returAmountPay;
        this.giroAmountPay = giroAmountPay;
        this.transferAmountPay = transferAmountPay;

        this.potonganAmount = potonganAmount;
        this.potonganNotes = potonganNotes;

        this.ftArPaymenthBean = ftArPaymenthBean;
        this.ftSaleshBean = ftSaleshBean;
        this.returBean = returBean;
        this.fgiroBean = fgiroBean;
        this.accAccountPotonganBean = accAccountPotonganBean;
        this.fuangMukaBean = fuangMukaBean;

        this.fdivisionBean = fdivisionBean;
        this.ref = ref;
        this.refDate = refDate;
        this.remark = remark;

        this.created = created;
        this.modified = modified;
        this.modifiedBy = modifiedBy

    }

}