<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>
          </v-card-title>

          <v-card-text>
            <v-container>

              <v-row class="rounded-lg blue lighten-4 mb-4">
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
<!--                  <v-text-field-->
<!--                      v-model="itemModified.remark"-->
<!--                      label="Nomor Resi"-->
<!--                      :rules="rulesNotEmtpy"-->
<!--                  ></v-text-field>-->

                  <v-autocomplete
                      v-model="model"
                      :items="computedItemsFtSalesh"
                      :loading="isLoading"
                      :search-input.sync="search"
                      color="white"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      item-value="API"
                      label="Public APIs"
                      placeholder="Start typing to Search"
                      prepend-icon="mdi-database-search"
                      return-object
                  ></v-autocomplete>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <span>Nama Expedisi Jumlah</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.ref"
                      label="Expedisi Ref/Payment"
                      :rules="rulesNotEmtpy"
                      dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-menu
                      v-model="menuDate1"
                      :close-on-content-click="false"
                      max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs } ">
                      <v-text-field
                          :value="computedDateFormattedDatefns(itemModified.refDate)"
                          prepend-inner-icon="mdi-calendar"
                          clearable
                          label="Tanggal Transfer"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="itemModified.bornDate = null"
                          dense
                          hide-details
                      ></v-text-field>
                    </template>

                    <v-date-picker
                        v-model="itemModified.refDate"
                        @change="menuDate1 = false"
                    ></v-date-picker>
                  </v-menu>

                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.remark"
                      label="ke No. Rekening"
                      :rules="rulesNotEmtpy"
                      dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="grey lighten-4">
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.cashAmountPay"
                      label="Nilai Paket(Hrg Barang)"
                      dense
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.giroAmountPay"
                      label="Biaya-biaya"
                      dense
                      type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="grey lighten-4">
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.transferAmountPay"
                      label="Ongkir"
                      dense
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.potonganAmount"
                      label="Potongan Ongkir"
                      dense
                      type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-autocomplete
                      v-model="itemModified.fdivisionBean"
                      :items="itemsFDivision"
                      :rules="rulesNotEmtpy"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Division/Branch"
                      :hint="`Division = Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean)}`"
                      persistent-hint
                      single-line
                  ></v-autocomplete>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
          ref="refUploadDialog"
          @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-dialog>
  </div>

</template>

<script>
import FtArPaymentdService from '../../../services/apiservices/ft-ar-paymentd-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FtArPaymentd from "../../../models/ft-ar-paymentd";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import {format, parseISO} from "date-fns";
import FtSaleshService from "@/services/apiservices/ft-salesh-service";

export default {
  name: "FtArPaymentdDialog",
  components: { CloseConfirmDialog, UploadImageDialog },
  props:{
    formMode: String,
  },
  data() {
    return {
      title: 'Ar Payment',
      snackBarMesage: '',
      snackbar: false,


      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      searchFtSaleshBean: '',

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FtArPaymentd(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },
      itemsFDivision: [],

      itemFtSalesh: [],


      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],


      descriptionLimit: 60,
      itemsFtSalesh: [],
      isLoading: false,
      model: null,
      search: null,


    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

    itemFtSaleshComputed(){

      // if (this.searchFtSaleshBean==null && this.formMode ===FormMode.EDIT_FORM && this.itemModified.ftSaleshBean>0) {
      //   return this.itemFtSalesh.filter(x => x.id === this.itemModified.ftSaleshBean)
      // }else if (this.searchFtSaleshBean !=null){
      //   if (this.searchFtSaleshBean.length >2){
      //     return this.itemFtSalesh.filter( x => x.description.toUpperCase().includes(this.searchFtSaleshBean.toUpperCase()))
      //   }else {
      //     return []
      //   }
      // }else {
      //   return []
      // }
      return this.itemFtSalesh
    },

    computedItemsFtSalesh () {
      return this.itemsFtSalesh.map(entry => {
        const Description = entry.invoiceno.length > this.descriptionLimit
            ? entry.invoiceno.slice(0, this.descriptionLimit) + '...'
            : entry.invoiceno

        return Object.assign({}, entry, { Description })
      })
    },


  },
  watch: {
    search: function (newValue, oldValue) {
      console.log(newValue)
      console.log(oldValue)

      // if (! oldValue) oldValue=''
      // if (newValue){
      //   if (newValue.length >3){
      //     this.fetchFtSaleshBean(newValue.substr(0,3))
      //
      //     if (oldValue.length <=3){
      //       this.fetchFtSaleshBean(newValue.substr(0,3))
      //     }else {
      //       if (oldValue.substr(0, 3) != newValue.substr(0,3)){
      //
      //         // this.fetchFtSaleshBean(newValue.substr(0,3))
      //
      //         if (this.computedItemsFtSalesh.length > 0) return
      //         // Items have already been requested
      //         if (this.isLoading) return
      //
      //         this.isLoading = true
      //
      //         FtSaleshService.getAllFtSaleshContaining(1, 100, "created", "DESC", newValue)
      //             .then(
      //                 response => {
      //
      //                 // console.log(`oke test: ${JSON.stringify(res.data)}`)
      //
      //                 const { items} = response.data
      //                 this.itemsFtSalesh = items
      //
      //             },
      //             error => {
      //               this.loading = false
      //               console.log(error.response)
      //             },
      //           )
      //           .finally(() => (this.isLoading = false))
      //
      //       }
      //     }
      //   }
      // }

      if (this.computedItemsFtSalesh.length > 5) return
      if (newValue.length <4) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true
      FtSaleshService.getAllFtSaleshContaining(1, 10, "created", "DESC", newValue)
          .then(
              response => {

              // console.log(`oke test: ${JSON.stringify(res.data)}`)

              const { items} = response.data
              this.itemsFtSalesh = items

          },
          error => {
            this.loading = false
            console.log(error.response)
          },
        )
        .finally(() => (this.isLoading = false))

    }

  },

  methods: {
    fetchFtSaleshBean(value){
      FtSaleshService.getAllFtSaleshContaining(1, 10, "created", "DESC", value).then(
              response => {

              // console.log(`oke test: ${JSON.stringify(response.data)}`)

              const { items} = response.data
              this.itemsFtSalesh = items

          },
          error => {
            this.loading = false
            console.log(error.response)
          },
        )

    },
    changeFtSalesh(){
      // this.$refs.refFtSaleshBean.blur()

      // this.itemModified.district1 = this.lookupFSubArea(this.itemModified.fsubAreaBean)
      // this.itemModified.city1 = this.lookupFAreaFromSubArea(this.itemModified.fsubAreaBean)
      // this.itemModified.state1 = this.lookupFRegionFromSubArea(this.itemModified.fsubAreaBean)

      if (this.searchFtSaleshBean){
        if (this.searchFtSaleshBean.length >7){
          // if (oldValue.length <=7){
          //   this.fetchFtSaleshBean(newValue.substr(0,7))
          // }else {
          //   if (oldValue.substr(0, 7) != newValue.substr(0,7)){
          //     this.fetchFtSaleshBean(newValue.substr(0,7))
          //   }
          // }

          this.fetchFtSaleshBean(this.searchFtSaleshBean.substr(0,7))

          // console.log(`${oldValue} >> ${newValue}`)

        }
      }

    },

    showDialogUpload(){
      if (this.itemModified.kode1 !==undefined &&
          this.itemModified.description !==undefined &&
          this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Kode, Deskripsi dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },

    completeUploadSuccess: function (val){


      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response=>{
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },

    showDialog(selectedIndex, item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FtArPaymentd(0, '', ''),
        this.itemModified =  new FtArPaymentd(0, '', ''),
        this.selectedIndex = -1
      }

      this.itemsFDivision = itemsFDivision

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {
          FtArPaymentdService.updateFtArPaymentd(this.itemModified).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemModified)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {
          FtArPaymentdService.createFtArPaymentd(this.itemModified).then(
              response =>{
                this.$emit('eventFromFormDialogNew', response.data)
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }
      }
    },
    saveCreateOnly(){
      FtArPaymentdService.createFtArPaymentd(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FtArPaymentdService.updateFtArPaymentd(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FtArPaymentdService.getFtArPaymentdById(item.id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

  }

}
</script>

<style scoped>

  .card-hover-opacity{
    opacity: 0.4;
  }
  .card-not-hover-opacity{
    opacity: 1;
    transition: opacity .4s ease-in-out;
  }

  .show-btns {
    color: blue !important;
    opacity: 1;
  }

</style>
