<template>

  <v-card class="mt-2 ml-2 mr-2">
    <FtArPaymentdTable ref="refFtArPaymentd"/>
  </v-card>

</template>

<script>
import FtArPaymentdTable from "../../components/pembayaran/ar-payment/FtArpaymentdTable";

export default {
  components: { FtArPaymentdTable },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>