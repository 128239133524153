import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtArPaymentdService {
    getApiUrl() {
        return API_URL
    }
    getAllFtArPaymentd(){
        return axios.get(API_URL + `getAllFtArPaymentd`, { headers: authHeader() });
    }
    getAllFtArPaymentdContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFtArPaymentdContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFtArPaymentdById(id){
        return axios.get(API_URL + `getFtArPaymentdById/${id}`, { headers: authHeader() });
    }
    getAllFtArPaymentdByParent(id){
        return axios.get(API_URL + `getAllFtArPaymentdByParent/${id}`, { headers: authHeader() });
    }
    getAllFtArPaymentdByParentIds(ids){
        return axios.get(API_URL + `getAllFtArPaymentdByParentIds/${ids}`, { headers: authHeader() });
    }

    getAllFtArPaymentdByParentInvoice(id){
        return axios.get(API_URL + `getAllFtArPaymentdByParentInvoice/${id}`, { headers: authHeader() });
    }
    getAllFtArPaymentdByParentPayment(id){
        return axios.get(API_URL + `getAllFtArPaymentdByParentPayment/${id}`, { headers: authHeader() });
    }

    updateFtArPaymentd(item){
        return axios.put(API_URL + `updateFtArPaymentd/${item.id}`, item, {headers: authHeader()})
    }
    createFtArPaymentd(item){
        return axios.post(API_URL + `createFtArPaymentd`, item, {headers: authHeader()})
    }
    deleteFtArPaymentd(id){
        return axios.delete(API_URL + `deleteFtArPaymentd/${id}`, {
            headers: authHeader()
        });
    }

    deleteFtArPaymentdAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFtArPaymentdAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }

    createFtArPaymentdAvatar(item){
        return axios.post(API_URL + `createFtArPaymentdAvatar`, item, {headers: authHeader()})
    }

    deleteAllFtArPaymentd(itemIds){

        return axios.delete(API_URL + `deleteAllFtArPaymentd`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtArPaymentdService()